import React, {useCallback, useEffect, useMemo, useState} from 'react'
import '../styles/pages/tinder.scss';
import '../components/styles/tinder-cards.scss';
import ButtonOne from './../components/ui/button-one'
import ButtonTwo from './../components/ui/button-two'
import testCardsData from '../dev_files/card-massive';
import PopUpDatePicker from './../components/elem/popup/popup-datepick';
import PopUpDatePickerMatch from './../components/elem/popup/popup-match';
import TinderCardWrapper from "../components/elem/tinder/tinder-card-wrapper";
import TinderCardsContainer from "../components/elem/tinder/tinder-cards-container";
import apiClient from "../hooks/apiClient";
import {ageCalculator} from "../hooks/ageCalculator";
import {useNavigate} from 'react-router-dom'
import FlowPages from '../components/effects/flow-pages';
import {toast} from "react-toastify";

import {ReactComponent as ReloadSVG} from '../images/reload.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faGear} from "@fortawesome/free-solid-svg-icons";
import PopUpTinderSettings from "../components/elem/popup/popup-tinder-settings";
import PopUpLimitsFound from "../components/elem/popup/popup-limits-found";


function Tinder({...props}) {
    const navigate = useNavigate()

    // ------------ Поля отвечающие за свайпалку --------------

    const [exitAnimationPositionX, setExitAnimationPositionX] = useState('100%')
    const [currentCardIndex, setCurrentCardIndex] = useState(0)
    const [swipeLeftFlag, setSwipeLeftFlag] = useState(false)
    const [swipeRightFlag, setSwipeRightFlag] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // ------------------ Попап с информацией о лимите ------------------
    const [isShowLimitsFoundPopup, setIsShowLimitsFoundPopup] = useState(false)
    const [textLimitsFound, setTextLimitsFound] = useState('')

    // ------------------ Меню настроек ------------------

    const [isShowSettingsPopup, setIsShowSettingsPopup] = useState(false)
    const [businessTypes, setBusinessTypes] = useState([{
        'value': undefined,
        'label': 'Среди всех'
    }, {
        'label': 'Продажи товаров',
        'value': 'sales'
    }, {
        'label': 'Оказание услуг',
        'value': 'service'
    }, {
        'label': 'Медиа-сфера [Блогинг, СМИ]',
        'value': 'media'
    }])
    const [fetchingBusinessType, setFetchingBusinessType] = useState()
    useEffect(() => {
        let bType = localStorage.getItem('fetching_business_type')
        let p_groups = businessTypes.filter((el) => el.value === bType)
        if (bType && p_groups.length !== 0) {
            setFetchingBusinessType(p_groups[0])
        } else {
            setFetchingBusinessType(businessTypes[0])
        }
    }, [businessTypes])

    // ------------ Загрузка информации о текущем юзере -------------

    const [userInfo, setUserInfo] = useState(undefined)
    useEffect(() => {
        async function fetchUserInfo() {
            const response = await apiClient.get(`/users/me`)
            setUserInfo(response.data)
        }

        fetchUserInfo()
    }, [setUserInfo])

    // ------------ Загрузка данных карточек -------------

    const numberOfFetchingCardsOnStart = useMemo(() => 5, [])
    const [cardsData, setCardsData] = useState([]) // Список карточек (данные)
    const getCardsData = useCallback(async (is_delete_dislikes = false) => { // TODO: добавить обработку ошибко
        setIsLoading(true)
        try {
            const response = await apiClient.get(
                `/feed/relevant?limit=${numberOfFetchingCardsOnStart}&is_delete_dislikes=${is_delete_dislikes}${fetchingBusinessType?.value ? ('&business_type=' + fetchingBusinessType?.value) : ''}`
            )
            setCardsData(response.data)
            if (response.data.length === 0) {
                setIsCardsRunOut(true)
            }
        } catch (e) {
            if (e.response.data.detail.startsWith('Вы достигли лимита')){
                setTextLimitsFound(e.response.data.detail)
                setIsShowLimitsFoundPopup(true)
            } else {
                toast.warning(e.response.data.detail, {
                    position: "bottom-center",
                    isLoading: false,
                    autoClose: true
                })
            }
        }
        setIsLoading(false)
    }, [fetchingBusinessType])

    useEffect(() => { // Первоначальная и обновленная загрузка карточек
        if (fetchingBusinessType) {
            setCardsData([])
            setCurrentCardIndex(0)
            setIsCardsRunOut(false)
            getCardsData()
            if (fetchingBusinessType) {
                localStorage.setItem('fetching_business_type', fetchingBusinessType.value)
            }
        }
    }, [fetchingBusinessType])

    // Если false - при достижении конца списка показываем загрузку, если true - пишем что карточки закончились
    const [isCardsRunOut, setIsCardsRunOut] = useState(false)

    // ---------------- Like -----------------
    const likeFunction = useCallback(async () => {
        let user = cardsData[currentCardIndex]
        setIsLoading(true)
        try {
            let response = await apiClient.post(`/feed/like/${user.next_user.user_id}`, {
                'get_next_user': true,
                'skip': numberOfFetchingCardsOnStart - 1,
                'business_type': fetchingBusinessType?.value
            })
            if (response.data.next_user) {
                setCardsData(prevState => [...prevState, response.data])
            } else {
                setIsCardsRunOut(true)
            }
        } catch (e) {
            if (e.response.data.detail.startsWith('Вы достигли лимита')){
                setTextLimitsFound(e.response.data.detail)
                setIsShowLimitsFoundPopup(true)
            } else {
                toast.warning(e.response.data.detail, {
                    position: "bottom-center",
                    isLoading: false,
                    autoClose: true
                })
            }
        }
        setIsLoading(false)

    }, [fetchingBusinessType, currentCardIndex, cardsData, numberOfFetchingCardsOnStart])

    // ---------------- Match -----------------
    const matchFunction = useCallback(async () => {
        let user = cardsData[currentCardIndex]
        let response = await apiClient.post(`/feed/like/${user.next_user.user_id}`, {
            'get_next_user': true,
            'skip': numberOfFetchingCardsOnStart - 1,
            'business_type': fetchingBusinessType?.value
        })
        if (response.data.next_user) {
            setCardsData(prevState => [...prevState, response.data])
        } else {
            setIsCardsRunOut(true)
        }
    }, [fetchingBusinessType, cardsData, currentCardIndex, numberOfFetchingCardsOnStart])

    // -------------- Функции выполняющиеся при свайпе влево и вправо -----------------
    // Функция дизлайка анкеты при свайпе влево
    const dislike = useCallback(async (disliking_user_id) => { // TODO: Добавить обработку ошибок
        setIsLoading(true)
        try {
            const response = await apiClient.post(`/feed/dislike/${disliking_user_id}`, {
                'get_next_user': true,
                'skip': numberOfFetchingCardsOnStart - 1,
                'business_type': fetchingBusinessType?.value
            })
            if (response.data.next_user) {
                setCardsData(prevState => [...prevState, response.data])
            } else {
                setIsCardsRunOut(true)
            }
        } catch (e) {}
        setIsLoading(false)
    }, [numberOfFetchingCardsOnStart, fetchingBusinessType])

    // Функция лайка при свайпе вправо
    const like = useCallback(async () => {
        let user = cardsData[currentCardIndex]
        if (!user.request) {
            await likeFunction()
        } else {
            await matchFunction()
        }
    }, [cardsData, currentCardIndex, matchFunction])

    // Кнопка пригласить друга
    const handleShare = useCallback(() => {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Поделитесь Collabs с друзьями',
                    text: '',
                    url: `https://t.me/impulse_collabs_bot?start=ref_${userInfo?.user_id}`,
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            console.log('Share not supported on this browser, do it the old way.');
        }
    }, [navigator, userInfo]);

    // Кнопка релоада
    const reloadCards = useCallback(() => {
        setCardsData([])
        setCurrentCardIndex(0)
        setIsCardsRunOut(false)
        getCardsData(true)
    }, [getCardsData])


    // --------------- Отображаемый массив объектов карточек ----------------
    const displayingCards = useMemo(() => {
        let arr = []
        if (currentCardIndex + 2 <= cardsData.length - 1) {
            arr.push(
                <TinderCardWrapper
                    onClick={() => {}}
                    key={currentCardIndex+2}
                    displayCardIndex={2}
                    cardIndex={currentCardIndex+2}
                    // Информация о пользователе
                    isCurrentUserLikedBySecondary={cardsData[currentCardIndex+2].request}
                    imageUrl={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${cardsData[currentCardIndex+2].next_user.photos[cardsData[currentCardIndex+2].next_user.photos.length - 1]?.image.file_name}`}
                    first_name={'@' + cardsData[currentCardIndex+2].next_user.instagram + ' - ' + cardsData[currentCardIndex+2].next_user.business_name}
                    second_name={cardsData[currentCardIndex+2].next_user.second_name}
                    age={ageCalculator(cardsData[currentCardIndex+2].next_user.birth_date)}
                    city={cardsData[currentCardIndex+2].next_user.city}
                    occupation={cardsData[currentCardIndex+2].next_user.business_type}
                    description={'Средний чек: ' + cardsData[currentCardIndex+2].next_user.average_payment + ' ₽'}
                    description2={'Подписчики: ' + cardsData[currentCardIndex+2].next_user.subscribers_count + ' чел.'}
                    // Функции, выполняющиеся по свайпу
                    onRightSwipeFunction={() => {}}
                    onLeftSwipeFunction={() => {}}
                    // Флаги, которые используются для свайпов влево-вправо по кнопке
                    swipeLeftFlag={false}
                    setSwipeLeftFlag={() => {}}
                    swipeRightFlag={false}
                    setSwipeRightFlag={() => {}}
                    // Текущая позиция карточки
                    exitAnimationPositionX={exitAnimationPositionX}
                    setExitAnimationPositionX={setExitAnimationPositionX}
                />
            )
        }
        if (currentCardIndex + 1 <= cardsData.length - 1) {
            arr.push(
                <TinderCardWrapper
                    onClick={() => {}}
                    key={currentCardIndex+1}
                    displayCardIndex={1}
                    cardIndex={currentCardIndex+1}
                    // Информация о пользователе
                    isCurrentUserLikedBySecondary={cardsData[currentCardIndex+1].request}
                    imageUrl={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${cardsData[currentCardIndex+1].next_user.photos[cardsData[currentCardIndex+1].next_user.photos.length - 1]?.image.file_name}`}
                    first_name={'@' + cardsData[currentCardIndex+1].next_user.instagram + ' - ' + cardsData[currentCardIndex+1].next_user.business_name}
                    second_name={cardsData[currentCardIndex+1].next_user.second_name}
                    age={ageCalculator(cardsData[currentCardIndex+1].next_user.birth_date)}
                    city={cardsData[currentCardIndex+1].next_user.city}
                    occupation={cardsData[currentCardIndex+1].next_user.business_type}
                    description={'Средний чек: ' + cardsData[currentCardIndex+1].next_user.average_payment + ' ₽'}
                    description2={'Подписчики: ' + cardsData[currentCardIndex+1].next_user.subscribers_count + ' чел.'}
                    // Функции, выполняющиеся по свайпу
                    onRightSwipeFunction={() => {}}
                    onLeftSwipeFunction={() => {}}
                    // Флаги, которые используются для свайпов влево-вправо по кнопке
                    swipeLeftFlag={false}
                    setSwipeLeftFlag={() => {}}
                    swipeRightFlag={false}
                    setSwipeRightFlag={() => {}}
                    // Текущая позиция карточки
                    exitAnimationPositionX={exitAnimationPositionX}
                    setExitAnimationPositionX={setExitAnimationPositionX}
                />
            )
        }
        if (currentCardIndex <= cardsData.length - 1) {
            arr.push(
                <TinderCardWrapper
                    onClick={() => navigate(`/profile?user_id=${cardsData[currentCardIndex].next_user.user_id}&&is_show_back_button=true`)}
                    key={currentCardIndex}
                    displayCardIndex={0}
                    cardIndex={currentCardIndex}
                    // Информация о пользователе
                    isCurrentUserLikedBySecondary={cardsData[currentCardIndex].request}
                    imageUrl={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${cardsData[currentCardIndex].next_user.photos[cardsData[currentCardIndex].next_user.photos.length - 1]?.image.file_name}`}
                    first_name={'@' + cardsData[currentCardIndex].next_user.instagram + ' - ' + cardsData[currentCardIndex].next_user.business_name}
                    second_name={cardsData[currentCardIndex].next_user.second_name}
                    age={ageCalculator(cardsData[currentCardIndex].next_user.birth_date)}
                    city={cardsData[currentCardIndex].next_user.city}
                    occupation={cardsData[currentCardIndex].next_user.business_type}
                    description={'Средний чек: ' + cardsData[currentCardIndex].next_user.average_payment + ' ₽'}
                    description2={'Подписчики: ' + cardsData[currentCardIndex].next_user.subscribers_count + ' чел.'}
                    // Функции, выполняющиеся по свайпу
                    onRightSwipeFunction={() => {
                        like()
                        setCurrentCardIndex(prevState => prevState + 1);
                    }}
                    onLeftSwipeFunction={() => {
                        dislike(cardsData[currentCardIndex].next_user.user_id);
                        setCurrentCardIndex(prevState => prevState + 1);
                    }}
                    // Флаги, которые используются для свайпов влево-вправо по кнопке
                    swipeLeftFlag={swipeLeftFlag}
                    setSwipeLeftFlag={setSwipeLeftFlag}
                    swipeRightFlag={swipeRightFlag}
                    setSwipeRightFlag={setSwipeRightFlag}
                    // Текущая позиция карточки
                    exitAnimationPositionX={exitAnimationPositionX}
                    setExitAnimationPositionX={setExitAnimationPositionX}
                />
            )
        }
        else {
            if (isCardsRunOut) {
                arr.push(
                    <div className="NothingCard">
                        <p>Пока что карточки закончились, но вы можете поделиться этим приложением с друзьями!</p>
                        <ButtonTwo onClick={handleShare}>Пригласить друга</ButtonTwo>
                        <ButtonTwo onClick={reloadCards}><ReloadSVG/></ButtonTwo>
                    </div>
                )
            } else {
                arr.push(
                    <div className="NothingCard">
                        <svg class="loader" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                            <circle class="loader__ring" cx="100" cy="100" r="82" fill="none" stroke-dasharray="0 257 1 257" stroke-dashoffset="0.01" stroke-linecap="round" transform="rotate(-90,100,100)" />
                            <line class="loader__ball" x1="100" y1="18" x2="100.01" y2="182" stroke-dasharray="1 165" stroke-linecap="round" />
                        </svg>
                    </div>
                )
            }
        }
        return arr
    }, [currentCardIndex, like, cardsData, exitAnimationPositionX, swipeLeftFlag, swipeRightFlag, isCardsRunOut, dislike])

    return (
        <FlowPages>
        <div className="tinderContainer">
            <TinderCardsContainer exitX={exitAnimationPositionX}>
                {displayingCards}
            </TinderCardsContainer>

            <div className='buttons'>
                <ButtonOne
                    onClick={() => {setSwipeLeftFlag(true)}}
                    disabled={(currentCardIndex > cardsData.length - 1) || isLoading}
                >
                    Пропустить
                    <div className="arrow-move">
                        <svg viewBox="0 0 190 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M189 0H20.0951C19.3676 0 18.6649 0.264377 18.1177 0.743912L1.37788 15.4159C0.0853165 16.5488 0.00694404 18.5339 1.20617 19.7651L18.0833 37.0932C18.648 37.673 19.423 38 20.2324 38H189L171.468 21.1638C170.238 19.9832 170.238 18.0168 171.468 16.8362L189 0Z" fill="white" fill-opacity="0.4"/>
                        </svg>
                    </div>
                </ButtonOne>
                <ButtonTwo
                    onClick={() => {setSwipeRightFlag(true)}}
                    disabled={(currentCardIndex > cardsData.length - 1) || isLoading}
                >
                    Хочу коллаб
                    <div className="arrow-move">
                        <svg viewBox="0 0 190 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H168.905C169.632 0 170.335 0.264377 170.882 0.743912L187.622 15.4159C188.915 16.5488 188.993 18.5339 187.794 19.7651L170.917 37.0932C170.352 37.673 169.577 38 168.768 38H0L17.5323 21.1638C18.7618 19.9832 18.7618 18.0168 17.5323 16.8362L0 0Z"/>
                        </svg>
                    </div>
                </ButtonTwo>
            </div>
            <PopUpTinderSettings
                isVisiblePopup={isShowSettingsPopup}
                setIsVisiblePopup={setIsShowSettingsPopup}
                userGroups={businessTypes}
                fetchingGroup={fetchingBusinessType}
                setFetchingGroup={(el) => {
                    setFetchingBusinessType(el)
                }}
            />
            <PopUpLimitsFound
                isVisiblePopup={isShowLimitsFoundPopup}
                setIsVisiblePopup={setIsShowLimitsFoundPopup}
                textLimitsFound={textLimitsFound}
                userId={userInfo?.user_id}
                shareFunc={handleShare}
            />
        </div>
        </FlowPages>
    )
}

export default Tinder;