import React from 'react'
import '../styles/popup.scss';

function Popup({children, popup, setPopup, onCloseFunction = (() => {}), ...props}) {
    
    return (
        <div className={`${popup ? "Popup show" : "Popup"}`} {...props}>
            <div className="PopupContent">
                <div className="close" onClick={() => {
                    onCloseFunction()
                    setPopup(false)
                }}>
                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_101_134)">
                        <path d="M19.7636 0.237119C19.4481 -0.0783887 18.9366 -0.0783887 18.6211 0.237119L0.236875 18.6213C-0.0786328 18.9368 -0.0786328 19.4483 0.236875 19.7638C0.394609 19.9216 0.601406 20.0005 0.808164 20.0005C1.01492 20.0005 1.22168 19.9216 1.37945 19.7638L19.7636 1.37962C20.0791 1.06415 20.0791 0.552627 19.7636 0.237119Z" fill="currentColor"/>
                        <path d="M19.7633 18.6213L1.37913 0.237119C1.06366 -0.0783887 0.5521 -0.0783887 0.236631 0.237119C-0.0788769 0.552588 -0.0788769 1.06411 0.236631 1.37962L18.6208 19.7638C18.7786 19.9216 18.9854 20.0005 19.1921 20.0005C19.3989 20.0005 19.6057 19.9216 19.7634 19.7639C20.0788 19.4483 20.0788 18.9368 19.7633 18.6213Z" fill="currentColor"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_101_134">
                        <rect fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="content">
                    {children}
                </div>
            </div>
        </div>
    )

}

export default Popup;