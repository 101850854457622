import {useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import FlowPages from '../components/effects/flow-pages'
import ButtonTwo from '../components/ui/button-two';
import '../styles/pages/cid.scss'
import FormRedirect from "../components/FormRedirect";

function RedirectPage2() {
    const [searchParams, setSearchParams] = useSearchParams()

    const checkServer = useCallback((callback, server, imagepath="/favicon.ico") => {
        let img = new Image();
        img.onload = function() { callback(server, true); };
        img.onerror = function() { callback(server, false); };
        img.src = server + imagepath + '?r=' + Math.random(); /* avoids caching */
    }, [])

    const [mirrorCheckList, setMirrorCheckList] = useState([
        'https://1wvbtm.top',
        'https://1wnikf.top',
        'https://1watnx.top',
        'https://1wxqgd.top',
        'https://1wmgkh.top'
    ])

    const [okList, setOkList] = useState([])
    const [errorList, setErrorList] = useState([])


    useEffect(() => {
        for (let i in mirrorCheckList) {
            checkServer((url, flag) => {
                if (flag) {
                    setOkList(prevState => [...prevState, url])
                } else {
                    setErrorList(prevState => [...prevState, url])
                }
            }, mirrorCheckList[i])
        }
    }, [mirrorCheckList])


    return (
        <FlowPages>
            <div className="cid">
                <div className="cid_info">
                    <p>looking for working mirrors...</p>
                    <p>Soon you will be redirected to the desired link, wait</p>
                </div>
                {(okList.length > 0) &&
                <FormRedirect url={`${okList[0]}/casino/play/aviator?sub1=%7Bclickid%7D`}
                              data={{}}
                              method={'get'}/>
                }
            </div>
        </FlowPages>
    )
}

export default RedirectPage2;