import React, { useEffect, useState } from 'react'
import {BrowserRouter} from 'react-router-dom';
import OpenFull from './components/elem/open-full';
import { useTelegram } from './hooks/useTelegram';
import { useTheme } from './hooks/useTheme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from "./AppRoutes";


function App() {

    const {theme, setTheme} = useTheme();
    const [isWebAppFullyOpened, setIsWebAppFullyOpened] = useState(true);
    const {tg} = useTelegram();

    useEffect(() => {
        tg.ready();
        // tg.expand(); Вот эта хуйня ломает жизни
    }, [])

    useEffect(() => {
        if (tg.initData) {
            setTheme('tg-' + tg.colorScheme);
        }
    }, [tg?.colorScheme])

    useEffect(() => { // В этот хук добавлять все обновления из библиотек не работающих с реактом
        if (tg.initData) {
            const intervalId = setInterval(() => {
                setIsWebAppFullyOpened(tg.isExpanded);
            }, 100)
            return () => clearInterval(intervalId);
        }
    }, [])

    return (
        <div className="AppView">
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
            <ToastContainer/>
            <OpenFull isWebAppFullyOpened={isWebAppFullyOpened} />
        </div>
    )

}

export default App;