import React, {useState, useEffect} from 'react';
import { motion } from 'framer-motion';

function FlowPages({children, className}) {

    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        setShouldShow(true);
    }, []);

    return ( 
        <motion.div
            initial={{
                opacity: 0,
                scale: 0.95
            }}
            animate={{
                opacity: shouldShow ? 1 : 0,
                scale: shouldShow ? 1 : 0.95
            }}
            transition={{
                duration: 0.4
            }}
            exit={{
                opacity: 0,
                scale: 0.95
            }}
            className={className}
        >
                    {children}
        </motion.div>
    );
}

export default FlowPages;