import React, {useCallback, useEffect, useMemo, useState} from 'react'
import '../styles/pages/evaluation.scss';

import ButtonOne from '../components/ui/button-one';
import ButtonTwo from '../components/ui/button-two';
import Star from '../components/elem/evaluation/evaluation-star';
import FlowPages from '../components/effects/flow-pages';
import {AnimatePresence} from "framer-motion";
import UploadPhoto from '../components/elem/evaluation/upload-photo';
import {useSearchParams} from "react-router-dom";
import apiClient from "../hooks/apiClient";
import {error_loading, start_loading, success_loading} from "../hooks/toastLoader";
import {useTelegram} from "../hooks/useTelegram";
import OneSelectUI from "../components/ui/forms/one-select";
import ToggleSwitch from "../components/ui/toggle";
  

function Evaluation() {
    const [searchParams, setSearchParams] = useSearchParams()

    // Причины не получилось встретиться
    const [badReasons, setBadReasons] = useState([
        {
            'value': 'Не интересен кандидат',
            'label': 'Не интересен кандидат'
        }, {
            'value': 'Кандидат не отвечает на сообщения',
            'label': 'Кандидат не отвечает на сообщения'
        }, {
            'value': 'Другое',
            'label': 'Другое'
        }
    ])
    const [selectedBadReason, setSelectedBadReason] = useState()

    const meetingID = useMemo(() => parseInt(searchParams.get('meeting_id')), [])
    const {tg} = useTelegram()

    // Получаем текущего юзера
    const [currentUser, setCurrentUser] = useState(undefined)
    useEffect(() => {
        async function fetchUserInfo() {
            const response = await apiClient.get(`/users/me`)
            setCurrentUser(response.data)
        }
        fetchUserInfo()
    }, [])

    // Получаем информацию о встрече
    const [meetingData, setMeetingData] = useState(undefined)
    useEffect(() => {
        async function fetchMeetingData() {
            let response = await apiClient(`/meetings`)
            let meetings = response.data
            let current_meeting = meetings.filter(meeting => meeting.meeting_id === meetingID)[0]
            if (current_meeting){
                setMeetingData(current_meeting)
            }
        }
        fetchMeetingData()
    }, [meetingID])

    // Получаем юзера с которым встречались
    const meetingUser = useMemo(() => {
        if (meetingData && currentUser) {
            return [meetingData.first_user, meetingData.second_user].filter(user => user.user_id !== currentUser.user_id)[0]
        }
    }, [meetingData, currentUser])


    // Общие поля
    const [isMeet, setIsMeet] = useState(true);
    const [text, setText] = useState('')

    // Поля при лайке
    const [rating, setRating] = useState(0);
    const [photos, setPhotos] = useState([]);
    const [publishImpulseFlag, setPublishImpulseFlag] = useState(false)

    const leave_a_review_no_meeting = useCallback(async () => {
        let toast_id = await start_loading('Оставляем отзыв')
        if (text.length === 0) {
            await error_loading(toast_id, 'Оставьте пожалуйста комментарий, почему встреча не состоялась')
            return
        }
        let data = {
            meeting_id: meetingID,
            rate: undefined,
            is_come: false,
            review: text,
            to_publish_impulse: false,
            to_publish_group: false,
            photos: []
        }
        try {
            let response = await apiClient.post('/meeting_reviews', data)
            await success_loading(toast_id, 'Вы успешно оставили отзыв о встрече')
            tg.close()
        } catch (e) {
            if (e.response && e.response.data && e.response.data.detail && !`${e.response.data.detail}`.includes('object')) {
                await error_loading(toast_id, `${e.response.data.detail}`)
                return
            }
            await error_loading(toast_id, 'Ошибка при оставлении отзыва, пожалуйста, напиши в тех поддержку')
        }
    }, [meetingID, text, tg])

    const leave_a_review = useCallback(async () => {
        let toast_id = await start_loading('Оставляем отзыв')
        if (rating === 0) {
            await error_loading(toast_id, 'Поставьте пожалуйста оценку')
            return
        }
        if ((publishImpulseFlag) && (text.length === 0)) {
            await error_loading(toast_id, 'Для публикации в чате заполните отзыв о встрече')
            return
        }
        let data = {
            meeting_id: meetingID,
            rate: rating,
            is_come: true,
            review: text,
            to_publish_impulse: publishImpulseFlag,
            to_publish_group: false,
            photos: photos.map((el) => el.image_id)
        }
        try {
            let response = await apiClient.post('/meeting_reviews', data)
            await success_loading(toast_id, 'Вы успешно оставили отзыв о встрече')
            tg.close()
        } catch (e) {
            if (e.response && e.response.data && e.response.data.detail && !`${e.response.data.detail}`.includes('object')) {
                await error_loading(toast_id, `${e.response.data.detail}`)
                return
            }
            await error_loading(toast_id, 'Ошибка при оставлении отзыва, пожалуйста, напиши в тех поддержку')
        }
    }, [publishImpulseFlag, meetingID, photos, rating, text, tg])

    if (!meetingData || !currentUser || !meetingUser) {
        return (
            <div className="NothingView">
                <svg className="loader" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <circle className="loader__ring" cx="100" cy="100" r="82" fill="none" stroke-dasharray="0 257 1 257" stroke-dashoffset="0.01" stroke-linecap="round" transform="rotate(-90,100,100)" />
                    <line className="loader__ball" x1="100" y1="18" x2="100.01" y2="182" stroke-dasharray="1 165" stroke-linecap="round" />
                </svg>
            </div>
        )
    }
    return (
        <FlowPages>
            <div className="evaluation">
                <div className="avatars">
                    <div className="onePer">
                        <img
                            src={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${meetingUser.photos[meetingUser.photos.length - 1].image.file_name}`}
                            alt=""
                        />
                    </div>
                    <div className="twoPer">
                        <img
                            src={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${currentUser.photos[currentUser.photos.length - 1].image.file_name}`}
                            alt=""
                        />
                    </div>
                </div>
                <div className="head-text">
                    <p>Оценка коллаборации с </p>
                    <p>брендом {meetingUser.business_name}</p>
                </div>

                <AnimatePresence mode={'wait'}>
                    {isMeet !== undefined && isMeet ?
                        <FlowPages key="3">
                            <div className="rate">
                                <Star value={rating} onClick={(value) => setRating(value)} />
                            </div>
                            <div className="review">
                                <span>Опишите, как прошла ваша коллаборация </span>
                                <textarea
                                    placeholder='Введите отзыв'
                                    onChange={(event => setText(event.target.value))}
                                    value={text}
                                />
                                <span>Будем рады если вы прикрепите фото результатов коллаборации и поделитесь в соцсетях отзывом с хэштегом <b>#collabs</b></span>
                                <UploadPhoto photos={photos} setPhotos={setPhotos} />
                                {false && <div className="switch">
                                    <ToggleSwitch isChecked={publishImpulseFlag} setIsChecked={setPublishImpulseFlag}/>
                                    <p>Опубликовать отзыв в основном чате IMPULSE</p>
                                </div>}
                                <ButtonTwo onClick={leave_a_review}>Отправить</ButtonTwo>
                            </div>
                        </FlowPages>

                    : isMeet !== undefined && !isMeet ?
                        <FlowPages key="2">
                            <div className="review">
                                <OneSelectUI
                                    selectionOptions={badReasons}
                                    setSelectedOption={e => {
                                        if (e.value === 'Другое') {
                                            setText('')
                                        } else {
                                            setText(e.value)
                                        }
                                        setSelectedBadReason(e)
                                    }}
                                    selectedOption={selectedBadReason}
                                    title={'Почему не получилось встретится?'}
                                />
                                {selectedBadReason?.value === 'Другое' &&
                                    <>
                                        <span>Опишите пожалуйста ситуацию</span>
                                        <textarea
                                            placeholder='Введите отзыв'
                                            onChange={(event => setText(event.target.value))}
                                            value={text}
                                        />
                                    </>
                                }
                                <ButtonTwo onClick={leave_a_review_no_meeting}>Отправить</ButtonTwo>
                            </div>
                        </FlowPages>
                    :
                        <FlowPages key="1">
                            {null}
                        </FlowPages>
                    }
                </AnimatePresence>
            </div>
        </FlowPages>
    )

}

export default Evaluation;