import React from 'react'

function OpenFull({isWebAppFullyOpened}) {

    return (
        <div className={isWebAppFullyOpened? "openFull" : "openFull view"}>
            <div className="infoBlock">
                <svg viewBox="0 0 62 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="path1" fill-rule="evenodd" clip-rule="evenodd" d="M61.0938 32.8684C62.3021 31.6169 62.3021 29.5905 61.0938 28.3422L35.4768 1.87485C33.0573 -0.624949 29.1322 -0.624949 26.7127 1.87485L0.906629 28.5339C-0.289184 29.7725 -0.30468 31.7736 0.875646 33.0283C2.08075 34.3119 4.06653 34.3241 5.29023 33.063L28.9029 8.66335C30.1142 7.41185 32.0752 7.41185 33.2865 8.66335L56.7133 32.8684C57.9215 34.1199 59.8856 34.1199 61.0938 32.8684Z" fill="currentColor" fill-opacity="0.7"/>
                    <path class="path2" fill-rule="evenodd" clip-rule="evenodd" d="M61.0938 56.8684C62.3021 55.6169 62.3021 53.5905 61.0938 52.3422L35.4768 25.8748C33.0573 23.3751 29.1322 23.3751 26.7127 25.8748L0.906629 52.5339C-0.289184 53.7725 -0.30468 55.7736 0.875646 57.0283C2.08075 58.3119 4.06653 58.3241 5.29023 57.063L28.9029 32.6634C30.1142 31.4118 32.0752 31.4118 33.2865 32.6634L56.7133 56.8684C57.9215 58.1199 59.8856 58.1199 61.0938 56.8684Z" fill="currentColor" fill-opacity="0.7"/>
                    <path class="path3" fill-rule="evenodd" clip-rule="evenodd" d="M61.0938 83.8684C62.3021 82.6169 62.3021 80.5905 61.0938 79.3422L35.4768 52.8748C33.0573 50.3751 29.1322 50.3751 26.7127 52.8748L0.906629 79.5339C-0.289184 80.7725 -0.30468 82.7736 0.875646 84.0283C2.08075 85.3119 4.06653 85.3241 5.29023 84.063L28.9029 59.6634C30.1142 58.4118 32.0752 58.4118 33.2865 59.6634L56.7133 83.8684C57.9215 85.1199 59.8856 85.1199 61.0938 83.8684Z" fill="currentColor" fill-opacity="0.7"/>
                </svg>
            </div>
        </div>
    );
}

export default OpenFull;