import React from 'react'
import '../../styles/popup-match.scss';
import Popup from '../../ui/popup'
import ButtonOne from '../../ui/button-one'
import OneSelectUI from '../../ui/forms/one-select';
import { useState } from 'react';

// const testMatchTimes = [
//     '2023-05-17T23:56',
//     '2023-05-17T23:56',
//     '2023-05-17T23:56',
//     '2023-05-17T23:56'
// ];

const testMatchTimes = [
    { value: '2023-05-17T23:56', label: '2023-05-17 23:56' },
    { value: '2023-05-17T23:57', label: '2023-05-17 23:57' },
    { value: '2023-05-17T23:58', label: '2023-05-17 23:58' },
    { value: '2023-05-17T23:59', label: '2023-05-17 23:59' },
];

function PopUpDatePickerMatch(
    {
        isVisiblePopup,
        setIsVisiblePopup,
        matchTimes,
        onConfirmFunction
    }
) {

    const [selectedTime, setSelectedTime] = useState('')

    return (
        <Popup popup={isVisiblePopup} setPopup={setIsVisiblePopup}>
            <h1>Ваш кандидат<br/>предложил время</h1>
            <p>Выберите удобное для Вас</p>

            <div className="selectMatchTime">
                <OneSelectUI
                    placeholder={'Выберите время'}
                    selectionOptions={matchTimes}
                    selectedOption={selectedTime}
                    setSelectedOption={setSelectedTime}
                />
            </div>
    
            <ButtonOne onClick={async () => {
                await onConfirmFunction(selectedTime)
                setSelectedTime('')
            }}>
                { !selectedTime ? 'Договоримся лично' : 'Согласиться на встречу'}
            </ButtonOne>
        </Popup>
    );
}

export default PopUpDatePickerMatch;