import Select from "react-select";
import React, {useState} from "react";
import '../../styles/input-from-ui.scss';


function OneSelectUI({title, selectedOption, setSelectedOption, selectionOptions, placeholder, required, checkErrorFunction = () => '', ...props}) {

    const [textWithError, setTextWithError] = useState('');

    return (
        <div className="InputFromUI">
            <span className="title">{title}<span className="req">{required && ' *'}</span></span>
            <Select
                className={textWithError ? 'select-comp error' : 'select-comp'}
                classNamePrefix="select-component"
                options={selectionOptions}
                placeholder={placeholder}
                value={selectedOption}
                onChange={(value) => {
                    setSelectedOption(value)
                    setTextWithError(checkErrorFunction(value))
                }}
                noOptionsMessage={() => 'Не найдено'}
                />
            {textWithError ? 
                <div className="errorText"><p>{textWithError}</p></div>
            :
                null
            }
        </div>
    )
}

export default OneSelectUI;