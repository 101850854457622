import React from 'react'
import '../../styles/part-card.scss';
import { Skeleton, Fade } from "@mui/material"
import {useNavigate} from "react-router-dom";

function PartCard({avatar_url, first_name, last_name, occupation, instagram, city, niche, needHelpText, userId, loading }) {

    const navigate = useNavigate()

    if (loading) {
        return (
            <Fade in={!!loading} timeout={1000}>
            <div className="part-card">
                <div className="profile-card">
                    <div className="avatar">
                        <Skeleton variant="circular" width="100%" height="100%" />
                    </div>
                    <div className="short-info">
                        <div className="name"><Skeleton variant="text" width="80%"/></div>
                        <div className="skills"><Skeleton variant="text" width="70%"/></div>
                        <div className="skills"><Skeleton variant="text" width="60%"/></div>
                    </div>
                </div>
                <div className="info">
                    <div className="info-block">
                        <span className="name-category" style={{opacity: 1}}><Skeleton variant="text" width="30%"/></span>
                        <p><Skeleton variant="text"/></p>
                        <p><Skeleton variant="text"/></p>
                    </div>
                    <div className="info-block">
                        <span className="name-category" style={{opacity: 1}}><Skeleton variant="text" width="30%"/></span>
                        <p><Skeleton variant="text" /></p>
                        <p><Skeleton variant="text" /></p>
                    </div>
                </div>
            </div>
            </Fade>
        );
    } else {
        return (
            <Fade in={!loading} timeout={1000}>
            <div
                className="part-card"
                onClick={() => navigate(`/profile?user_id=${userId}&&is_show_back_button=true`)}
            >
                <div className="profile-card">
                    <div className="avatar">
                        <img src={avatar_url} alt="" />
                    </div>
                    <div className="short-info">
                        <div className="name">{first_name}{last_name ? ` ${last_name}`: ''}</div>
                        <div className="skills">
                            <div className="info shadowfilter">
                                {niche}
                            </div>
                        </div>
                        <div className="city">{city}</div>
                    </div>
                </div>
                <div className="info">
                    {instagram && <div className="info-block">
                        <span className="name-category">Инстаграм</span>
                        <p>{instagram}</p>
                    </div>}
                    {occupation && <div className="info-block">
                        <span className="name-category">Род деятельности</span>
                        <p>{occupation}</p>
                    </div>}
                    {needHelpText && <div className="info-block">
                        <span className="name-category">Необходимая помощь</span>
                        <p>{needHelpText}</p>
                    </div>}
                </div>
            </div>
            </Fade>
        );
    }
    
}

export default PartCard;