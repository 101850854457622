import React, {useEffect, useMemo, useState} from 'react'
import '../styles/pages/participants.scss';

import PartCard from '../components/elem/part-list/part-card';
import groups from '../dev_files/participants-list';
import FlowPages from '../components/effects/flow-pages';
import apiClient from "../hooks/apiClient";

function Participants() {

    const [isLoading, setIsLoading] = useState(true)

    const [userGroups, setUserGroups] = useState([])
    const [userGroup, setUserGroup] = useState(undefined)
    useEffect(() => {
        async function fetchMembersOfGroup() {
            setIsLoading(true)
            const response = await apiClient.get('/groups/my')
            const user_groups = response.data
            setUserGroups(user_groups)
            if (user_groups.length === 0) {
                setIsLoading(false)
                return
            }
            const first_user_group = user_groups[0]
            setUserGroup(first_user_group)
            setIsLoading(false)
        }
        fetchMembersOfGroup()
    }, [])

    const [inputSearch, setInputSearch] = useState('')
    const groupUsers = useMemo(() => {
        if (!userGroup) {
            setInputSearch('')
            return []
        }
        const filteredUsers = userGroup.users.filter(
            user => (
                `${user.first_name} ${user.last_name} ${user.general_niche} ${user.main_niches?.join(' ')} ${user.need_help_niches?.join(' ')} ${user.occupation} ${user.can_help_text} ${user.need_help_text}`
            ).toLowerCase().includes(inputSearch.toLowerCase())
        )
        return filteredUsers
    }, [inputSearch, userGroup])

    return (
        <FlowPages>
        <div className="participants-list">
            <div className="part-search">
                <input type="text" className="search" placeholder='Поиск'
                       value={inputSearch} onChange={e => setInputSearch(e.target.value)}/>
            </div>
            {isLoading ? (
                <div className="part-list">
                    <PartCard loading />
                    <PartCard loading />
                    <PartCard loading />
                </div>
            ) : (
                <>
                    {!!userGroups && (
                        <div className={'chips_container'}>
                            {userGroups.map((el) =>
                                <div
                                    className={`chips_item ${el.group_id === userGroup.group_id ? 'active' : ''}`}
                                    onClick={() => {setUserGroup(el)}}
                                >
                                    {el.group_name}
                                </div>
                            )}
                        </div>
                    )}
                    {groupUsers.length > 0 ? (
                        <>
                            <div className="part-list">
                                {groupUsers.map((person, index) => (
                                    <PartCard
                                        key={index}
                                        avatar_url={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${person.photos[person.photos.length - 1].image.file_name}`}
                                        first_name={person.first_name}
                                        last_name={person.last_name}
                                        occupation={person.occupation}
                                        instagram={person.instagram}
                                        city={person.city}
                                        niche={person.general_niche.name}
                                        needHelpText={person.need_help_text}
                                        userId={person.user_id}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className="part-list">
                            <p className='NoGroup'>Ничего не найдено</p>
                        </div>
                    )}
                </>
            )}
        </div>
        </FlowPages>
    )

}

export default Participants;