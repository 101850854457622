import React, {useCallback, useMemo, useRef, useState} from 'react'
import '../styles/pages/register.scss'
import ButtonTwo from '../components/ui/button-two'

import InputTextUI from '../components/ui/forms/input-text'
import {useSearchParams} from "react-router-dom"
import apiClient from "../hooks/apiClient"
import {useTelegram} from "../hooks/useTelegram";
import FlowPages from '../components/effects/flow-pages'
import {error_loading, start_loading, success_loading} from "../hooks/toastLoader";

import instExampleImg from '../images/inst_screen_example.jpg';
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ButtonOne from "../components/ui/button-one";

function Register() {
    const [searchParams, setSearchParams] = useSearchParams()
    const {tg} = useTelegram()

    // Поля для регистрации

    // [Бренд]
    const [businessType, setBusinessType] = useState(undefined)
    const [businessName, setBusinessName] = useState('')
    const [avatar, setAvatar] = useState(null)
    const [instagram, setInstagram] = useState('')
    const [subscribersCount, setSubscribersCount] = useState('')
    const [averagePayment, setAveragePayment] = useState('')

    const [stage, setStage] = useState(1)

    const referrer_user_id = useMemo(() => {
        return searchParams.get('referrer_user_id')
    }, [searchParams])


    // Отправка фото для аватара
    const fileInputRef = useRef(null)
    const handleFileInput = useCallback(async (event) => {
        let toast_id = await start_loading('Загружаем вашу фотографию')
        let data = new FormData()
        data.append('file_image', event.target.files[0])
        try {
            const response = await apiClient.post(
                '/files', data
            )
            await success_loading(toast_id, 'Фотография успешно загружена')
            return response.data
        } catch (e) {
            if (e.response && e.response.data && e.response.data.detail) {
                await error_loading(toast_id, e.response.data.detail)
            } else {
                await error_loading(toast_id, 'Произошла ошибка при загрузке файла')
            }
        }
        return undefined
    }, [])

    const signup = async () => {
        let toast_id = await start_loading('Регистрируем вас')
        if (
            !businessType || !businessName || !averagePayment
        ) {
            await error_loading(toast_id, 'Вы не заполнили одно или несколько обязательных полей (1 этап)')
            return
        }
        if (!avatar) {
            await error_loading(toast_id, 'Загрузите картинку (2 этап)')
            return
        }
        if (
            !instagram ||
            !subscribersCount
        ) {
            await error_loading(toast_id, 'Вы не заполнили одно или несколько обязательных полей (3 этап)')
            return
        }
        let data;
        try {
            data = {
                "image_ids": [avatar?.image_id],

                "business_type": businessType.trim(),
                "business_name": businessName.trim(),
                "instagram": instagram.trim(),
                "subscribers_count": subscribersCount.trim(),
                "average_payment": averagePayment.trim(),

                "is_participant": true,

                "referrer_user_id": referrer_user_id
            }
        } catch (e) {
            await error_loading(toast_id, `Ошибка при регистрации, напишите в т.п.: ${e.toString()}`)
            return
        }

        try {
            await apiClient.post('/users', data)
            await success_loading(toast_id, 'Вы успешно зарегистировались')
            localStorage.clear()
            tg.close()
        } catch (e) {
            if (e.response && e.response.data && e.response.data.detail && !`${e.response.data.detail}`.includes('object')) {
                await error_loading(toast_id, `${e.response.data.detail}`)
                return
            }
            await error_loading(toast_id, 'Ошибка при регистрации, проверьте пожалуйста правильность всех полей. Если всё ок - напишите в тех поддержку')
        }
    }

    return (
        <FlowPages>
            <div className="registration">
                <div className="registration-form">
                    <div className="reg-info">
                        <h1>Регистрация участника Collabs</h1>
                        {(stage === 1) && <p>Пожалуйста, заполните все пункты с «<span style={{color: 'red'}}>*</span>» чтобы получить
                            доступ к проекту</p>}
                    </div>
                    {(stage > 1) &&
                        <ButtonOne onClick={() => {
                            setStage(prevState => prevState - 1)
                            window.scrollTo(0, 0)
                        }}>
                            Назад [ {stage} / 3 ]
                        </ButtonOne>
                    }
                    {(stage === 1) &&
                        <FlowPages>
                            <div className="reg-inputs">
                                <InputTextUI
                                    title={<>Сфера бизнеса</>}
                                    placeholder={'Укажите сферу вашего бизнеса'}
                                    text={businessType}
                                    setText={setBusinessType}
                                    required
                                />
                                <InputTextUI
                                    title="Название вашего бизнеса [бренд]"
                                    placeholder="Don Donuts"
                                    text={businessName}
                                    setText={setBusinessName}
                                    required
                                    checkErrorFunction={(value) => {
                                        if (value.length <= 2) {
                                            return 'Название бизнеса должно иметь не меньше 2 символов'
                                        }
                                        return ''
                                    }}
                                />
                                <InputTextUI
                                    title="Средний чек услуги/товара в ₽"
                                    placeholder="1500"
                                    text={averagePayment}
                                    setText={setAveragePayment}
                                    required
                                    checkErrorFunction={(value) => {
                                        if (!/^\d+$/.test(value)) {
                                            return 'Укажите только число'
                                        }
                                        return ''
                                    }}
                                />
                            </div>
                        </FlowPages>
                    }
                    {(stage === 2) &&
                        <FlowPages>
                            <div className="reg-inputs">
                                <br/>
                                <InputTextUI
                                    title={<>Инстаграм бренда</>}
                                    placeholder={'Имя пользователя на английском без @'}
                                    text={instagram}
                                    setText={setInstagram}
                                    required
                                    refactorTextFunction={(str) => {
                                        const link_regex = /https?:\/\/(www\.)?instagram.com\/([\w\d.-]+)/
                                        const link_match = str.match(link_regex);
                                        if (link_match) {
                                            return link_match[2];
                                        }
                                        return str
                                    }}
                                    checkErrorFunction={(value) => {
                                        if (!/^([a-zA-Z0-9_.]{3,35})$/.test(value) && value.length !== 0) {
                                            return 'Поле должно содержать только числа, латинские буквы и допустимые символы (точка и нижнее подчеркивание) и быть размером от 3 до 35 символов'
                                        }
                                        return ''
                                    }}
                                />
                                <InputTextUI
                                    title={<>Укажите число подписчиков инстаграм аккаунта</>}
                                    placeholder={'400'}
                                    text={subscribersCount}
                                    setText={setSubscribersCount}
                                    required
                                    checkErrorFunction={(value) => {
                                        if (!/^\d+$/.test(value)) {
                                            return 'Укажите только число'
                                        }
                                        return ''
                                    }}
                                />
                            </div>
                        </FlowPages>
                    }
                    {(stage === 3) &&
                        <FlowPages>
                            <div className="reg-inputs">
                                <br/>
                                <div className="input-avatar">
                            <span>Загрузите скриншот инстаграм профиля вашего бизнеса<span
                                style={{color: 'red'}}>*</span>
                            </span>
                                    <div className="avatar" onClick={() => fileInputRef.current.click()}>
                                        <input type="file" id="avatar" name="avatar" accept="image/*"
                                               onChange={async (event) =>
                                                   setAvatar(await handleFileInput(event))}
                                               ref={fileInputRef}
                                               style={{display: 'none'}}/>
                                        {!avatar ?
                                            <FontAwesomeIcon icon={faPlus} className={'faicon'}/>
                                            :
                                            <img
                                                src={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${avatar.file_name}`}
                                                alt=""/>
                                        }
                                    </div>
                                </div>
                                <div className={'photo-example'}>
                                    <span>Скриншот должен выглядеть примерно так</span>
                                    <div className={'img-example-container'}>
                                        <img src={instExampleImg}/>
                                    </div>
                                </div>
                            </div>
                        </FlowPages>
                    }
                </div>
                {(stage < 3) ?
                    <ButtonTwo onClick={() => {
                        setStage(prevState => prevState + 1)
                        window.scrollTo(0, 0)
                    }}>
                        Далее
                    </ButtonTwo>
                    :
                    <ButtonTwo onClick={signup}>
                        Зарегистрироваться
                    </ButtonTwo>
                }
            </div>
        </FlowPages>
    )

}

export default Register