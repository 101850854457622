import React, { useState } from 'react'
import '../styles/button-one.scss';
import Ripple from '../../components/effects/ripple'

function ButtonOne({children, ...props}) {

    return (
        <button className='ButtonOne' {...props}>
            {children}
            <Ripple className="Ripple"/>
        </button>
    )

}

export default ButtonOne;