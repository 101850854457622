import {useSearchParams} from "react-router-dom";
import {useMemo} from "react";
import FlowPages from '../components/effects/flow-pages'
import ButtonTwo from '../components/ui/button-two';
import '../styles/pages/cid.scss'

function RedirectPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    window.location.href = useMemo(() => {
        return searchParams.get('redirect_url') + (document.location.search)
    }, [])
    return (
        <FlowPages>
            <div className="cid">
                <div className="cid_info">
                    <p>Сейчас вы будете перенаправлены на нужную ссылку.</p>
                    <p>Если перенаправление не произошло нажмите на кнопку ниже.</p>
                    <br/>
                    <ButtonTwo onClick={() => window.location.href = (searchParams.get('redirect_url') + document.location.search)}>Перейти</ButtonTwo>
                </div>
            </div>
        </FlowPages>
    )
}

export default RedirectPage;