import React from 'react'
import '../../styles/popup-tinder-settings.scss';
import Popup from '../../ui/popup'
import ButtonOne from '../../ui/button-one'
import OneSelectUI from '../../ui/forms/one-select';
import { useState } from 'react';


function PopUpTinderSettings(
    {
        isVisiblePopup,
        setIsVisiblePopup,
        userGroups,
        fetchingGroup,
        setFetchingGroup
    }
) {
    return (
        <Popup popup={isVisiblePopup} setPopup={setIsVisiblePopup}>
            <h1>Настройки подбора</h1>
            <p>Выберите группу, среди участников которой будет вестись подбор анкет</p>

            <div>
                <OneSelectUI
                    selectionOptions={userGroups}
                    selectedOption={fetchingGroup}
                    setSelectedOption={setFetchingGroup}
                />
            </div>

            <ButtonOne onClick={() => setIsVisiblePopup(false)}>
                Закрыть настройки
            </ButtonOne>
        </Popup>
    );
}

export default PopUpTinderSettings;