import React, {useMemo, useState, useRef, useEffect} from 'react'
import '../../styles/popup-datepicker.scss';
import Popup from '../../ui/popup'
import ButtonOne from '../../ui/button-one'
import {ReactComponent as CalendarSVG} from '../../../images/calendar.svg';

function PopUpDatePicker(
    {
        isVisible,
        setIsVisible,
        onCloseFunction,
        onConfirmFunction
    }) {

    const [timeOne, setTimeOne] = useState("");
    const [isOpenDateTimePickerOne, setIsOpenDateTimePickerOne] = useState(false);
    const [timeTwo, setTimeTwo] = useState("");
    const [isOpenDateTimePickerTwo, setIsOpenDateTimePickerTwo] = useState(false);
    const [timeThree, setTimeThree] = useState("");
    const [isOpenDateTimePickerThree, setIsOpenDateTimePickerThree] = useState(false);

    const timeOneRef = useRef();
    const timeTwoRef = useRef();
    const timeThreeRef = useRef();

    const selectedTimes = useMemo(() => {
        let arr = []
        if (timeOne) {
            let date = new Date(timeOne)
            let date_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
            date_utc = new Date(date_utc)
            arr.push(date_utc.toISOString().slice(0, date_utc.toISOString().lastIndexOf(":")))
        }
        if (timeTwo) {
            let date = new Date(timeTwo)
            let date_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
            date_utc = new Date(date_utc)
            arr.push(date_utc.toISOString().slice(0, date_utc.toISOString().lastIndexOf(":")))
        }
        if (timeThree) {
            let date = new Date(timeThree)
            let date_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
            date_utc = new Date(date_utc)
            arr.push(date_utc.toISOString().slice(0, date_utc.toISOString().lastIndexOf(":")))
        }
        return arr
    }, [timeOne, timeTwo, timeThree])

    const OpenDateTimePickerOne = () => {
        setIsOpenDateTimePickerOne(true)
    };

    const OpenDateTimePickerTwo = () => {
        setIsOpenDateTimePickerTwo(true)
    };

    const OpenDateTimePickerThree = () => {
        setIsOpenDateTimePickerThree(true)
    };

    useEffect(() => {
        if (isOpenDateTimePickerOne && timeOneRef.current) {
            timeOneRef.current.focus();
        }
    }, [isOpenDateTimePickerOne, timeOneRef.current]);
    
    useEffect(() => {
        if (isOpenDateTimePickerTwo && timeTwoRef.current) {
            timeTwoRef.current.focus();
        }
    }, [isOpenDateTimePickerTwo, timeTwoRef.current]);
    
    useEffect(() => {
        if (isOpenDateTimePickerThree && timeThreeRef.current) {
            timeThreeRef.current.focus();
        }
    }, [isOpenDateTimePickerThree, timeThreeRef.current]);

    const minDatePickFormatted = useMemo(() => {
        let currentDate = new Date();
        let hoursToAdd = 3;
        let newDate = new Date(Math.round((currentDate.getTime() + hoursToAdd * 60 * 60 * 1000) / (60 * 30 * 1000)) * 60 * 30 * 1000);
        return newDate.toISOString().slice(0, newDate.toISOString().lastIndexOf(":"))
    }, [isOpenDateTimePickerOne, isOpenDateTimePickerTwo, isOpenDateTimePickerThree])

    const maxDatePickFormatted = useMemo(() => {
        let currentDate = new Date();
        let hoursToAdd = 24*7;
        let newDate = new Date(Math.round((currentDate.getTime() + hoursToAdd * 60 * 60 * 1000) / (60 * 30 * 1000)) * 60 * 30 * 1000);
        return newDate.toISOString().slice(0, newDate.toISOString().lastIndexOf(":"))
    }, [isOpenDateTimePickerOne, isOpenDateTimePickerTwo, isOpenDateTimePickerThree])

    return (
        <Popup popup={isVisible} setPopup={setIsVisible} onCloseFunction={onCloseFunction}>
            <h1>Укажите удобное<br/>время для встречи</h1>
            <p>Мы предложим эти даты вашему<br/>кандидату</p>

            <div className="selectTimeContainer">
                <button className='selectTime'>
                    <CalendarSVG/>
                    {isOpenDateTimePickerOne === false ? (
                        <span onClick={OpenDateTimePickerOne}>Укажите первый вариант даты</span>
                    ) : (
                        <input
                            type="datetime-local"
                            min={minDatePickFormatted}
                            max={maxDatePickFormatted}
                            step="1800"
                            value={timeOne}
                            onChange={event => setTimeOne(event.target.value)}
                            ref={timeOneRef}
                        />
                    )}
                </button>
                <button className='selectTime'>
                    <CalendarSVG/>
                    {isOpenDateTimePickerTwo === false ? (
                        <span onClick={OpenDateTimePickerTwo}>Укажите второй вариант даты</span>
                    ) : (
                        <input
                            type="datetime-local"
                            min={minDatePickFormatted}
                            max={maxDatePickFormatted}
                            step="1800"
                            value={timeTwo}
                            onChange={event => setTimeTwo(event.target.value)}
                            ref={timeTwoRef}
                        />
                    )}
                </button>
                <button className='selectTime'>
                    <CalendarSVG/>
                    {isOpenDateTimePickerThree === false ? (
                        <span onClick={OpenDateTimePickerThree}>Укажите третий вариант даты</span>
                    ) : (
                        <input
                            type="datetime-local"
                            min={minDatePickFormatted}
                            max={maxDatePickFormatted}
                            step="1800"
                            value={timeThree}
                            onChange={event => setTimeThree(event.target.value)}
                            ref={timeThreeRef}
                        />
                    )}
                </button>
            </div>
            <ButtonOne onClick={async () => {
                await onConfirmFunction(selectedTimes)

                setIsOpenDateTimePickerOne(false)
                setIsOpenDateTimePickerTwo(false)
                setIsOpenDateTimePickerThree(false)
                setTimeOne('')
                setTimeTwo('')
                setTimeThree('')
            }}>
                {
                    !isOpenDateTimePickerOne && !isOpenDateTimePickerTwo && !isOpenDateTimePickerThree
                        ? 'Предложить встречу без даты'
                        : 'Предложить встречу'
                }
            </ButtonOne>
        </Popup>
    );
}

export default PopUpDatePicker;