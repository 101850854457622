import React, {useCallback, useMemo, useRef, useState} from 'react'
import '../../styles/upload-photo.scss';
import FlowPages from '../../effects/flow-pages';
import { AnimatePresence } from 'framer-motion';
import {error_loading, start_loading, success_loading} from "../../../hooks/toastLoader";
import apiClient from "../../../hooks/apiClient";

function PhotoPlaceholder() {
    return ( 
        <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.5 29C47.9041 29 45.3665 29.7698 43.2081 31.212C41.0498 32.6542 39.3675 34.704 38.3741 37.1023C37.3807 39.5006 37.1208 42.1396 37.6272 44.6856C38.1336 47.2316 39.3837 49.5702 41.2192 51.4058C43.0548 53.2413 45.3934 54.4914 47.9394 54.9978C50.4854 55.5042 53.1245 55.2443 55.5227 54.2509C57.921 53.2575 59.9709 51.5753 61.413 49.4169C62.8552 47.2585 63.625 44.7209 63.625 42.125C63.625 38.644 62.2422 35.3056 59.7808 32.8442C57.3194 30.3828 53.981 29 50.5 29Z" fill="#696969"/>
            <path d="M50.5 13.25C43.2316 13.25 36.1263 15.4054 30.0828 19.4435C24.0393 23.4816 19.329 29.2212 16.5474 35.9364C13.7659 42.6516 13.0382 50.0408 14.4562 57.1696C15.8742 64.2984 19.3743 70.8466 24.5138 75.9862C29.6534 81.1257 36.2016 84.6258 43.3304 86.0439C50.4592 87.4619 57.8484 86.7341 64.5636 83.9526C71.2788 81.171 77.0184 76.4607 81.0565 70.4172C85.0947 64.3737 87.25 57.2685 87.25 50C87.239 40.2567 83.3636 30.9155 76.4741 24.0259C69.5845 17.1364 60.2433 13.261 50.5 13.25ZM71.4803 73.4302C71.4281 69.9869 70.0252 66.7019 67.5737 64.2832C65.1223 61.8645 61.8188 60.5059 58.375 60.5H42.625C39.1813 60.5059 35.8777 61.8645 33.4263 64.2832C30.9749 66.7019 29.5719 69.9869 29.5197 73.4302C24.7594 69.1797 21.4025 63.5835 19.8934 57.3827C18.3843 51.1819 18.7942 44.669 21.0689 38.7064C23.3435 32.7437 27.3757 27.6126 32.6313 23.9925C37.8869 20.3723 44.1182 18.4339 50.5 18.4339C56.8818 18.4339 63.1131 20.3723 68.3687 23.9925C73.6244 27.6126 77.6565 32.7437 79.9311 38.7064C82.2058 44.669 82.6157 51.1819 81.1066 57.3827C79.5975 63.5835 76.2406 69.1797 71.4803 73.4302Z" fill="#696969"/>
            <rect x="0.5" y="0.5" width="99" height="99" rx="14.5" stroke="#696969" stroke-dasharray="2 2"/>
        </svg>
     );
}

function PhotoDelete({onClick}) {
    return ( 
        <div className="del" onClick={onClick}>
            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7636 0.236875C19.4481 -0.0786328 18.9366 -0.0786328 18.6211 0.236875L0.236883 18.6211C-0.0786252 18.9366 -0.0786252 19.4481 0.236883 19.7636C0.394617 19.9214 0.601414 20.0002 0.808172 20.0002C1.01493 20.0002 1.22169 19.9214 1.37946 19.7636L19.7636 1.37937C20.0791 1.06391 20.0791 0.552383 19.7636 0.236875Z"/>
                <path d="M19.7634 18.6211L1.37918 0.236875C1.06371 -0.0786328 0.552145 -0.0786328 0.236677 0.236875C-0.0788312 0.552344 -0.0788312 1.06387 0.236677 1.37937L18.6209 19.7636C18.7786 19.9214 18.9854 20.0002 19.1922 20.0002C19.3989 20.0002 19.6057 19.9214 19.7634 19.7636C20.0789 19.4481 20.0789 18.9366 19.7634 18.6211Z"/>
            </svg>
        </div>
     );
}

function UploadPhoto({photos, setPhotos}) {
    const photosLimit = useMemo(() => 3, [])
    const photoSelect = useRef();

    const handleFileInput = useCallback(async (event) => {
        let toast_id = await start_loading('Загружаем вашу фотографию')
        let data = new FormData()
        data.append('file_image', event.target.files[0])
        try {
            const response = await apiClient.post(
                '/files', data
            )
            setPhotos(prev => [...prev, response.data])
            await success_loading(toast_id, 'Фотография успешно загружена')
        } catch (e) {
            if (e.response && e.response.data && e.response.data.detail) {
                await error_loading(toast_id, e.response.data.detail)
            } else {
                await error_loading(toast_id, 'Произошла ошибка при загрузке файла')
            }
        }
    }, [])

    return (
        <div className="upload-photo">
            {photos.map((photo, index) => (
                <div
                    className="selectPhoto"
                    key={photo.file_name}
                >
                    <img src={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${photo.file_name}`} alt=""/>
                    <PhotoDelete
                        onClick={() => setPhotos(prev => [...prev.slice(0, index), ...prev.slice(index + 1)])}/>
                </div>
            ))}
            {(photos.length < photosLimit) && <div

                className="selectPhoto"
                key='add_photo'
                onClick={() => {
                    photoSelect.current.click();
                }}
            >
                <input
                    type="file"
                    id={`photo-1000`}
                    name={`photo-1000`}
                    accept="image/*"
                    ref={photoSelect}
                    style={{display: 'none'}}
                    onChange={handleFileInput}
                />
                {<PhotoPlaceholder/>}
            </div>}
        </div>
    );
}

export default UploadPhoto;