import InputMask from "react-input-mask";
import React, {useState} from "react";
import '../../styles/input-from-ui.scss';

function InputTextUI({title, placeholder, text, refactorTextFunction = (el) => el, setText, mask, type = 'text', checkErrorFunction = () => '', required, ...props}) {

    const [textWithError, setTextWithError] = useState('');

    return (
        <div className="InputFromUI">
            <span className="title">{title}<span className="req">{required && ' *'}</span></span>
            <InputMask
                className={textWithError ? 'textInput error' : 'textInput'}
                type={type}
                placeholder={placeholder}
                value={text}
                onChange={event => {
                    let t = refactorTextFunction(event.target.value)
                    setText(t)
                    setTextWithError(checkErrorFunction(t))
                }}
                mask={mask}
                maskChar=" "
                required={required}
            />
            {textWithError ? 
                <div className="errorText"><p>{textWithError}</p></div>
            :
                null
            }
        </div>
    )
}

export default InputTextUI;