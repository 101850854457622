import React, { useState } from 'react'
import '../styles/header.scss';
import {useNavigate} from 'react-router-dom'

function Header({isShowBackButton, children, ...props}) {
    const navigate = useNavigate()
    return (
        <div className="header">
            {isShowBackButton ? (
            <button className="back" onClick={() => navigate(-1)}>
                <svg viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11612 9.11612C0.627961 9.60427 0.627961 10.3957 1.11612 10.8839L9.07107 18.8388C9.55922 19.327 10.3507 19.327 10.8388 18.8388C11.327 18.3507 11.327 17.5592 10.8388 17.0711L3.76777 10L10.8388 2.92893C11.327 2.44078 11.327 1.64932 10.8388 1.16117C10.3507 0.67301 9.55922 0.67301 9.07107 1.16117L1.11612 9.11612ZM27 8.75L2 8.75V11.25L27 11.25V8.75Z"/>
                </svg>
            </button>
            ): null }

            <div className="namePage">{children}</div>
        </div>
    )
}

export default Header;