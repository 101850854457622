import { motion, AnimatePresence } from "framer-motion";


function TinderCardsContainer({children, exitX, ...props}) {
    return (
        <motion.div className={"cardContainer"} style={{position: "relative"}}>
            <AnimatePresence initial={false} custom={{exitX}}>
                {children}
            </AnimatePresence>
        </motion.div>
    )
}

export default TinderCardsContainer;