import React from 'react'
import '../../styles/popup-tinder-settings.scss';
import Popup from '../../ui/popup'
import ButtonOne from '../../ui/button-one'
import OneSelectUI from '../../ui/forms/one-select';
import { useState } from 'react';
import ButtonTwo from "../../ui/button-two";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import {toast} from "react-toastify";


function PopUpLimitsFound(
    {
        isVisiblePopup,
        setIsVisiblePopup,
        textLimitsFound,
        userId,
        shareFunc
    }
) {
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        toast.success('Скопировано!', {
            position: "bottom-center",
            isLoading: false,
            autoClose: true
        })
    };
    return (
        <Popup popup={isVisiblePopup} setPopup={setIsVisiblePopup}>
            <h1>Увеличьте лимит, пригласив одного участника!</h1>
            <p style={{'margin-bottom': '2rem'}}>
                {textLimitsFound}
                <br/>
                <br/>
                Вместе мы сделаем знакомства полезнее, а сообщество больше!
            </p>
            <div className={'copyContainer'}>
                <input className={'copyInput'} value={`https://t.me/business_tinder_robot?start=ref_${userId}`}/>
                <span
                    className={'copyIcon'}
                    onClick={() => handleCopy(`https://t.me/business_tinder_robot?start=ref_${userId}`)}
                >
                    <FontAwesomeIcon icon={faCopy}/>
                </span>
            </div>
            <br/>
            <br/>
            <br/>
            <ButtonTwo onClick={shareFunc}>
                Поделиться
            </ButtonTwo>
        </Popup>
    );
}

export default PopUpLimitsFound;