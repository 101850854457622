import {toast} from "react-toastify";


export async function start_loading(message) {
    return toast.loading(
        message,
        {position: "bottom-center"}
    )
}

export async function error_loading(toast_id, message) {
    toast.update(toast_id, {
        render:message,
        type: "error",
        position: "bottom-center",
        isLoading: false,
        autoClose: true
    })
}

export async function success_loading(toast_id, message) {
    toast.update(toast_id, {
        render:message,
        type: "success",
        position: "bottom-center",
        isLoading: false,
        autoClose: true
    })
}
