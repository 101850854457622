import React, {useEffect, useState} from 'react'
import {motion, transform, useMotionValue, useTransform} from "framer-motion";


function Card(
    {
        image,
        motionVariants,
        children,
        onRightSwipeFunction,
        onLeftSwipeFunction,
        // Флаги, которые используются для свайпов влево-вправо по кнопке
        swipeLeftFlag,
        setSwipeLeftFlag,
        swipeRightFlag,
        setSwipeRightFlag,
        cardIndex,
        exitAnimationPositionX,
        setExitAnimationPositionX,

        onClick,
        ...props
}) {
    const x = useMotionValue(0);
    const scale = useTransform(x, [-300, 0, 300], [0.6, 1, 0.6]);
    const rotate = useTransform(x, [-300, 0, 300], [-25, 0, 25], {
        clamp: false
    });

    function handleDragEnd(event, info) {
        if (info.offset.x < -100) {
            setExitAnimationPositionX(-250);
            console.log('Свайп влево')
            onLeftSwipeFunction()
        } else if (info.offset.x > 100) {
            setExitAnimationPositionX(250);
            console.log('Свайп вправо')
            onRightSwipeFunction()
        }
    }
    useEffect(() => {
        console.log(exitAnimationPositionX)
    }, [exitAnimationPositionX])

    // Кнопка свайп влево
    useEffect(() => {
        if (swipeLeftFlag) {
            console.log('swipe <--')
            setExitAnimationPositionX(-250);
            onLeftSwipeFunction()
            setSwipeLeftFlag(false)
        }
    }, [swipeLeftFlag])

    // Кнопка свайп вправо
    useEffect(() => {
        if (swipeRightFlag) {
            console.log('swipe -->')
            setExitAnimationPositionX(250);
            onRightSwipeFunction()
            setSwipeRightFlag(false)
        }
    }, [swipeRightFlag])

    return (

        <motion.div
            onClick={onClick}
            key={cardIndex}
            className={"cardSwipe"}
            style={{
                x,
                rotate,
                cursor: "grab",
                transform: 'translateZ(0)'
            }}
            whileTap={{cursor: "grabbing"}}
            // Перемещение
            drag='x'
            dragConstraints={{top: 0, right: 0, bottom: 0, left: 0}}
            onDragEnd={handleDragEnd}
            // Анимация
            custom={{ exitX: exitAnimationPositionX }}
            variants={motionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <motion.div
                className={"cardSwipeElem"}
                style={{
                    transform: 'translateZ(0)',
                    scale,
                    backgroundImage: `url(${image})`
                }}
            >
                {children}
            </motion.div>
        </motion.div>
    );
}

export default Card;