import React from 'react'
import { Skeleton, Fade } from "@mui/material"

function InfoBlock({nameCategory, text, list, loading}) {

    const listItems = list ? list.map((item) => <li>{item}</li>) : null;
    const listText = text ? text.map((item) => <p>{item}</p>) : null;

    if (loading){
        return(
            <div className="info-block">
                <span className="name-category"><Skeleton variant="text" width="30%"/></span>
                <div className='text'><Skeleton variant="text" width="100%"/></div>
                <div className='text'><Skeleton variant="text" width="100%"/></div>
            </div>
        )
    } else {
        return(
            <div className="info-block">
                <span className="name-category">{nameCategory}</span>
                {listText ? 
                    <div className='text'>{listText}</div>
                :
                    null
                }
                {listItems ? 
                    <ul>{listItems}</ul>
                :
                    null
                }
            </div>
        )
    }
}

export default InfoBlock;