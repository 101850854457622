import React, {useState} from "react";
import { Skeleton } from "@mui/material";
import '../../styles/input-from-ui.scss';


function InputLoading({...props}) {

    return (
        <div className="InputFromUI loading">
            <span className="title"><Skeleton variant="text" width="80%" /></span>
            <div className="load-form">
                <Skeleton variant="rectangular" width="100%"/>
            </div>
        </div>
    )
}

export default InputLoading;