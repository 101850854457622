import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import '../styles/pages/cid.scss'
import FlowPages from '../components/effects/flow-pages'
import {useSearchParams} from "react-router-dom";

function Video() {
    const [searchParams, setSearchParams] = useSearchParams()
    const embedLink = useMemo(() => {
        return searchParams.get('embed_link')
    }, [searchParams])
    return (
        <iframe className='full_width full_height'
                src={embedLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
    )

}

export default Video