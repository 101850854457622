import React, { useState } from 'react'
import '../styles/button-two.scss';
import Ripple from '../../components/effects/ripple'

function ButtonTwo({children, ...props}) {

    return (
        <button className='ButtonTwo' {...props}>
            {children}
            <Ripple className="Ripple"/>
        </button>
    )

}

export default ButtonTwo;