import {Route, Routes, useLocation} from "react-router-dom";
import Tinder from "./pages/tinder";
import Register from "./pages/registration";
import ProfileEdit from "./pages/profile-edit";
import Participants from "./pages/participants";
import Evaluation from "./pages/evaluation";
import Profile from "./pages/profile";
import CID from "./pages/ci_dev";
import React from "react";
import {AnimatePresence} from "framer-motion";
import RedirectPage from "./pages/redirect-page";
import RedirectPage2 from "./pages/redirect-page2";
import Video from "./pages/video";

function AppRoutes() {
    const location = useLocation()

    return (
        <AnimatePresence mode={'wait'}>
            <Routes key={location.pathname} location={location}>
                <Route exact path="/" element={<Tinder/>} />
                <Route exact path="/signup" element={<Register/>} />
                <Route exact path="/profile-edit" element={<ProfileEdit/>} />
                <Route exact path="/part-list" element={<Participants/>} />
                <Route exact path="/evaluation" element={<Evaluation/>} />
                <Route exact path="/profile" element={<Profile/>} />
                <Route exact path="/currently_in_development" element={<CID/>} />
                <Route exact path="/redirect" element={<RedirectPage/>}/>
                <Route exact path="/mirror" element={<RedirectPage2/>}/>
                <Route exact path="/video" element={<Video/>}/>
            </Routes>
        </AnimatePresence>
    )
}

export default AppRoutes