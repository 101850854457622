

export function ageCalculator (dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function ageToBirthdate(age) {
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    const birthMonth = today.getMonth() - 1; // вычитаем 1 месяц
    const birthDay = today.getDate();
    return new Date(birthYear, birthMonth, birthDay);
}
