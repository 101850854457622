import React from 'react'

function StarSVG({...props}) {
    return(
        <svg {...props} viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 0L28.0718 13.2621L43 15.2791L32.1353 25.492L34.787 40L21.5 33.0483L8.21121 40L10.8647 25.492L0 15.2791L14.9282 13.2621L21.5 0Z"/>
        </svg>
    )
}

const Star = ({ value, onClick }) => {
    const renderStars = () => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= value) {
                stars.push(<StarSVG key={i} className="active" onClick={() => onClick(i)} />);
            } else {
                stars.push(<StarSVG key={i} className="" onClick={() => onClick(i)} />);
            }
        }
        return stars;
    };

        return (
            renderStars()
        );
};

export default Star;