import React, {useEffect, useRef} from 'react';

function FormRedirect({ url, data, method = 'post' }) {
    const formRef = useRef(null);

    useEffect(() => {
        Object.keys(data).forEach(key => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            formRef.current.appendChild(input);
        });
        formRef.current.action = url;
        formRef.current.method = method;
        formRef.current.submit();
    }, [data, method, url]);

    return <form ref={formRef} style={{ display: 'none' }} />;
}
export default FormRedirect;
