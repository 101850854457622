import {rem} from "polished";
import React, {useCallback, useState} from "react";
import Card from "./tinder-card";
import UserLikedYou from "../userLikedYou";
import {faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowRestore} from "@fortawesome/free-regular-svg-icons";
import CanBeOpened from "../canBeOpened";

const nextNextCardMotionVariants = {
    initial: () => ({scale: 0, y: rem(75), opacity: 0}),
    animate: () => ({scale: 0.9, y: rem(80), opacity: 0}),
    exit: ({exitX}) => ({
        x: exitX,
        opacity: 0,
        scale: 0
    })
};

const nextCardMotionVariants = {
    initial: () => ({scale: 0, y: rem(75), opacity: 0}),
    animate: () => ({scale: 0.9, y: rem(80), opacity: 0.5}),
    exit: ({exitX}) => ({
        x: exitX,
        opacity: 0,
        scale: 0.5
    })
};

const currentCardMotionVariants = {
    initial: () => ({}),
    animate: () => ({scale: 1, y: 0, opacity: 1}),
    exit: ({exitX}) => ({
        x: exitX,
        opacity: 0,
        scale: 0.5
    })
};


function TinderCardWrapper(
    {
        displayCardIndex,
        cardIndex,
        // Информация о пользователе
        isCurrentUserLikedBySecondary,
        imageUrl,
        first_name,
        second_name,
        age,
        city,
        occupation,
        description,
        description2,
        // Функции, выполняющиеся по свайпу
        onRightSwipeFunction,
        onLeftSwipeFunction,
        // Флаги, которые используются для свайпов влево-вправо по кнопке
        swipeLeftFlag,
        setSwipeLeftFlag,
        swipeRightFlag,
        setSwipeRightFlag,
        // Текущая позиция карточки
        exitAnimationPositionX,
        setExitAnimationPositionX,

        onClick,
        ...props
    }) {

    const cropText = useCallback((text, max_length = 64) => {
        if (text === 'sales') {
            text = 'Продажи'
        } else if (text === 'service') {
            text = 'Услуги'
        } else if (text === 'media') {
            text = 'Медиа-сфера [блогинг, СМИ]'
        }
        if (text) {
            if (text.length > max_length) {
                text = text.slice(0, max_length) + "...";
            }
        }
        return text
    }, [])

    // Информация которая отображается на карточке
    let cardInfo = (
        <>
            <div className="card-info">
                <p className="name">{first_name}</p>
                <p className="city">{(cropText(occupation) ? `${cropText(occupation, 75)}`: '')}</p>
                <p className="desc">{description ? (description) : undefined}</p>
                <p className="desc">{description2 ? (description2) : undefined}</p>
            </div>
            {isCurrentUserLikedBySecondary ?
                <UserLikedYou/>
                : <CanBeOpened/>
            }
        </>
    )

    // Сама карточка
    let cardItem = <></>
    if (displayCardIndex === 0) {
        cardItem = (
            <Card
                key={cardIndex}
                onClick={onClick}
                cardIndex={cardIndex}
                motionVariants={currentCardMotionVariants}
                image={imageUrl}
                onRightSwipeFunction={onRightSwipeFunction}
                onLeftSwipeFunction={onLeftSwipeFunction}
                swipeLeftFlag={swipeLeftFlag}
                swipeRightFlag={swipeRightFlag}
                setSwipeRightFlag={setSwipeRightFlag}
                setSwipeLeftFlag={setSwipeLeftFlag}
                exitAnimationPositionX={exitAnimationPositionX}
                setExitAnimationPositionX={setExitAnimationPositionX}
            >
                {cardInfo}
            </Card>
        )
    } else if (displayCardIndex === 1) {
        cardItem = (
            <Card
                key={cardIndex}
                onClick={() => {}}
                cardIndex={cardIndex}
                motionVariants={nextCardMotionVariants}
                image={imageUrl}
                onRightSwipeFunction={onRightSwipeFunction}
                onLeftSwipeFunction={onLeftSwipeFunction}
                swipeLeftFlag={swipeLeftFlag}
                swipeRightFlag={swipeRightFlag}
                setSwipeRightFlag={setSwipeRightFlag}
                setSwipeLeftFlag={setSwipeLeftFlag}
                exitAnimationPositionX={0}
                setExitAnimationPositionX={() => {}}
            >
                {cardInfo}
            </Card>
        )
    } else {
        cardItem = (
            <Card
                key={cardIndex} // TODO:
                onClick={() => {}}
                cardIndex={cardIndex}
                motionVariants={nextNextCardMotionVariants}
                image={imageUrl}
                onRightSwipeFunction={onRightSwipeFunction}
                onLeftSwipeFunction={onLeftSwipeFunction}
                swipeLeftFlag={swipeLeftFlag}
                swipeRightFlag={swipeRightFlag}
                setSwipeRightFlag={setSwipeRightFlag}
                setSwipeLeftFlag={setSwipeLeftFlag}
                exitAnimationPositionX={0}
                setExitAnimationPositionX={() => {}}
            >
                {cardInfo}
            </Card>
        )
    }

    return cardItem
}

export default TinderCardWrapper;