import React, {useState, useMemo, useEffect, useRef, useCallback} from 'react'
import '../styles/pages/profile-edit.scss';
import ButtonTwo from '../components/ui/button-two'

import citiesList from '../dev_files/register-cities';

import InputTextUI from '../components/ui/forms/input-text';
import OneSelectUI from '../components/ui/forms/one-select';
import FlowPages from '../components/effects/flow-pages';
import InputLoading from '../components/ui/forms/input-loading';
import apiClient from "../hooks/apiClient";
import {error_loading, start_loading, success_loading} from "../hooks/toastLoader";
import Header from "../components/ui/header";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocation, faPhone, faPlus} from "@fortawesome/free-solid-svg-icons";
import InputTextAreaUI from "../components/ui/forms/input-textarea";
import CreatableMultiSelectUI from "../components/ui/forms/async-creatable-multi-select";
import MediaServiceInput from "../components/elem/mediaServiceInput/mediaServiceInput";
import instExampleImg from "../images/inst_screen_example.jpg";


function ProfileEdit() {

    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

    // Поля для регистрации

    // [Бренд]
    const [businessType, setBusinessType] = useState(undefined)
    const [businessName, setBusinessName] = useState('')
    const [avatar, setAvatar] = useState(null)
    const [instagram, setInstagram] = useState('')
    const [subscribersCount, setSubscribersCount] = useState('')
    const [averagePayment, setAveragePayment] = useState('')

    useEffect(() => {
        async function fetchUserInfo() {
            const response = await apiClient.get(`/users/me`)
            setIsLoading(false)

            setBusinessType(response.data.business_type)
            setBusinessName(response.data.business_name)
            setInstagram(response.data.instagram)
            setSubscribersCount(response.data.subscribers_count + '')
            setAveragePayment(response.data.average_payment + '')
            setAvatar(response.data.photos[response.data.photos.length - 1].image)
        }

        fetchUserInfo()
    }, [])

    const fileInputRef = useRef(null)

    const handleFileInput = useCallback(async (event) => {
        let toast_id = await start_loading('Загружаем вашу фотографию')
        let data = new FormData()
        data.append('file_image', event.target.files[0])
        try {
            const response = await apiClient.post(
                '/files', data
            )
            await success_loading(toast_id, 'Фотография успешно загружена')
            return response.data
        } catch (e) {
            if (e.response && e.response.data && e.response.data.detail) {
                await error_loading(toast_id, e.response.data.detail)
            } else {
                await error_loading(toast_id, 'Произошла ошибка при загрузке файла')
            }
        }
        return undefined
    }, [])

    const profile_edit = useCallback(async () => {
        let toast_id = await start_loading('Вносим изменения')
        if (
            !businessType || !businessName || !averagePayment
        ) {
            await error_loading(toast_id, 'Вы не заполнили одно или несколько обязательных полей (1 этап)')
            return
        }
        if (!avatar) {
            await error_loading(toast_id, 'Загрузите картинку (2 этап)')
            return
        }
        if (
            !instagram ||
            !subscribersCount
        ) {
            await error_loading(toast_id, 'Вы не заполнили одно или несколько обязательных полей (3 этап)')
            return
        }
        let data;
        try {
            data = {
                "image_ids": [avatar?.image_id],

                "business_type": businessType.trim(),
                "business_name": businessName.trim(),
                "instagram": instagram.trim(),
                "subscribers_count": subscribersCount.trim(),
                "average_payment": averagePayment.trim(),
            }
        } catch (e) {
            await error_loading(toast_id, `Ошибка при сохранении, напишите в т.п.: ${e.toString()}`)
            return
        }

        try {
            await apiClient.patch('/users/me', data)
            await success_loading(toast_id, 'Вы успешно изменили данные')
            localStorage.clear()
        } catch (e) {
            if (e.response && e.response.data && e.response.data.detail && !`${e.response.data.detail}`.includes('object')) {
                await error_loading(toast_id, `${e.response.data.detail}`)
                return
            }
            await error_loading(toast_id, 'Ошибка при изменении данных, проверьте пожалуйста правильность всех полей. Если всё ок - напишите в тех поддержку')
        }
    })

    return (
        <FlowPages>
            <Header isShowBackButton={true}/>
            <div className="profile-edit">
                {isLoading ?
                    <FlowPages className="edit-form" key="11">
                        <InputLoading/>
                        <InputLoading/>
                        <InputLoading/>
                        <InputLoading/>
                        <InputLoading/>
                        <InputLoading/>
                        <InputLoading/>
                    </FlowPages>
                    :
                    <FlowPages className="edit-form" key="22">
                        <InputTextUI
                            title={<>Сфера бизнеса</>}
                            placeholder={'Укажите сферу вашего бизнеса'}
                            text={businessType}
                            setText={setBusinessType}
                            required
                        />
                        <InputTextUI
                            title="Название вашего бизнеса [бренд]"
                            placeholder="Don Donuts"
                            text={businessName}
                            setText={setBusinessName}
                            required
                            checkErrorFunction={(value) => {
                                if (value.length <= 2) {
                                    return 'Название бизнеса должно иметь не меньше 2 символов'
                                }
                                return ''
                            }}
                        />
                        <InputTextUI
                            title="Средний чек услуги/товара в ₽"
                            placeholder="1500"
                            text={averagePayment}
                            setText={setAveragePayment}
                            required
                            checkErrorFunction={(value) => {
                                if (!/^\d+$/.test(value)) {
                                    return 'Укажите только число'
                                }
                                return ''
                            }}
                        />
                        <InputTextUI
                            title={<>Укажите ссылку на инстаграм аккаунт бренда</>}
                            placeholder={'https://instagram.com/impulse.365'}
                            text={instagram}
                            setText={setInstagram}
                            required
                            refactorTextFunction={(str) => {
                                const link_regex = /https?:\/\/(www\.)?instagram.com\/([\w\d.-]+)/
                                const link_match = str.match(link_regex);
                                if (link_match) {
                                    return link_match[2];
                                }
                                return str
                            }}
                            checkErrorFunction={(value) => {
                                if (!/^([a-zA-Z0-9_.]{3,35})$/.test(value) && value.length !== 0) {
                                    return 'Поле должно содержать только числа, латинские буквы и допустимые символы (точка и нижнее подчеркивание) и быть размером от 3 до 35 символов'
                                }
                                return ''
                            }}
                        />
                        <InputTextUI
                            title={<>Укажите число подписчиков инстаграм аккаунта</>}
                            placeholder={'400'}
                            text={subscribersCount}
                            setText={setSubscribersCount}
                            required
                            checkErrorFunction={(value) => {
                                if (!/^\d+$/.test(value)) {
                                    return 'Укажите только число'
                                }
                                return ''
                            }}
                        />
                        <div className="reg-inputs">
                            <br/>
                            <div className="input-avatar">
                            <span>Загрузите скриншот инстаграм профиля вашего бизнеса<span
                                style={{color: 'red'}}>*</span>
                            </span>
                                <div className="avatar" onClick={() => fileInputRef.current.click()}>
                                    <input type="file" id="avatar" name="avatar" accept="image/*"
                                           onChange={async (event) =>
                                               setAvatar(await handleFileInput(event))}
                                           ref={fileInputRef}
                                           style={{display: 'none'}}/>
                                    {!avatar ?
                                        <FontAwesomeIcon icon={faPlus} className={'faicon'}/>
                                        :
                                        <img
                                            src={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${avatar.file_name}`}
                                            alt=""/>
                                    }
                                </div>
                            </div>
                        </div>
                        <ButtonTwo onClick={profile_edit}>
                            Сохранить
                        </ButtonTwo>
                    </FlowPages>
                }
            </div>
        </FlowPages>
    )

}

export default ProfileEdit;