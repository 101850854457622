import React, { useEffect, useState } from 'react'
import '../styles/likeyou.scss';
import {faWindowRestore} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function canBeOpened() {

    return (
        <div className="likeYou">
            <div className="like">
            </div>
            <div className="request">
                Нажмите для просмотра профиля
            </div>
        </div>
    )

}

export default canBeOpened;