import React, { useEffect, useState } from 'react'
import '../styles/likeyou.scss';

function Approve() {
    return(
        <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1691 38.1691C30.1102 38.1691 38.1691 30.1102 38.1691 20.1691C38.1691 10.228 30.1102 2.16907 20.1691 2.16907C10.228 2.16907 2.16907 10.228 2.16907 20.1691C2.16907 30.1102 10.228 38.1691 20.1691 38.1691ZM20.1691 40.1691C31.2148 40.1691 40.1691 31.2148 40.1691 20.1691C40.1691 9.12337 31.2148 0.169067 20.1691 0.169067C9.12337 0.169067 0.169067 9.12337 0.169067 20.1691C0.169067 31.2148 9.12337 40.1691 20.1691 40.1691Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.8024 12.4244C31.212 12.7949 31.2436 13.4273 30.873 13.8368L17.5173 28.5984L9.44099 20.8893C9.04149 20.5079 9.02679 19.8749 9.40809 19.4754C9.78949 19.0759 10.4225 19.0612 10.822 19.4426L17.4124 25.7334L29.39 12.495C29.7605 12.0855 30.3929 12.0538 30.8024 12.4244Z" fill="white"/>
        </svg>
    )
}

function UserLikedYou() {

    return (
        <div className="likeYou">
            <div className="like">
                <Approve/>
            </div>
            <div className="request">
                Пользователь предложил вам встретится
            </div>
        </div>
    )

}

export default UserLikedYou;