import React, {useCallback, useEffect, useMemo, useState} from 'react'
import '../styles/pages/profile.scss';

import {Skeleton, Fade} from "@mui/material"

import UserLikedYou from '../components/elem/userLikedYou';
import ButtonOne from '../components/ui/button-one';
import ButtonTwo from '../components/ui/button-two';

import InfoBlock from '../components/ui/info-block';
import apiClient from "../hooks/apiClient";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ageCalculator} from "../hooks/ageCalculator";

import Header from '../components/ui/header'
import {useTelegram} from "../hooks/useTelegram";
import FlowPages from '../components/effects/flow-pages';
import PopUpDatePickerMatch from "../components/elem/popup/popup-match";
import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck, faCheckCircle,
    faCircleInfo,
    faInfo,
    faList,
    faLocation,
    faLocationDot,
    faPencil
} from "@fortawesome/free-solid-svg-icons";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";


function Profile() {

    const navigate = useNavigate()

    // Получаем id пользователя или строку 'me', если айди не указан
    const [searchParams, setSearchParams] = useSearchParams()
    const userIdOrMe = useMemo(() => {
        const user_id = searchParams.get('user_id')
        if (!user_id) {
            return 'me'
        }
        return user_id
    }, [])

    const [userHasLiked, setUserHasLiked] = useState(false)
    const [lastLikeReaction, setLastLikeReaction] = useState()
    useEffect(() => {
        async function fetch_last_reaction() {
            let response = await apiClient.get('/tinder_reactions/last_reaction_for_7_days', {
                params: {
                    second_user_id: userIdOrMe,
                    is_like: true
                }
            })
            let last_reaction = response.data
            if (last_reaction?.request) {
                setLastLikeReaction(last_reaction)
                if (!last_reaction.request.is_accepted && !last_reaction.request.is_rejected) {
                    setUserHasLiked(true)
                }
            }
        }

        if (userIdOrMe !== 'me') {
            fetch_last_reaction()
        }
    }, [userIdOrMe])

    const isShowBackButton = useMemo(() => !!searchParams.get('is_show_back_button'), [searchParams])

    // Получаем информацию о пользователе
    const [userInfo, setUserInfo] = useState(undefined)
    useEffect(() => {
        async function fetchUserInfo() {
            const response = await apiClient.get(`/users/${userIdOrMe}`)
            setUserInfo(response.data)
            setIsLoading(false)
        }

        fetchUserInfo()
    }, [userIdOrMe, setUserInfo])

    const [isLoading, setIsLoading] = useState(true)

    const [isUser, setIsUser] = useState(false)
    const [isLikedYou, setIsLikedYou] = useState(false) // TODO: пофиксить

    const {tg} = useTelegram()

    // ---------------- Date Picker Popup при мэтче -----------------

    const [isShowMatchPopup, setIsShowMatchPopup] = useState(false)
    const currentMatchTimes = useMemo(() => {
        let times = [];
        if (userHasLiked && lastLikeReaction.request) {
            for (let time_index in lastLikeReaction.request.times) {
                let current_datetime_from_utc = (new Date(lastLikeReaction.request.times[time_index].meeting_at  + '.000Z'))
                const diffInHours = (new Date()).getTimezoneOffset() / -60;
                current_datetime_from_utc.setHours(current_datetime_from_utc.getHours() + diffInHours)
                //console.log('date in utc', current_datetime_from_utc, lastLikeReaction.request.times[time_index].meeting_at)
                //console.log('label', current_datetime_from_utc.toISOString().slice(0, current_datetime_from_utc.toISOString().lastIndexOf(":")))
                times.push({
                    'value': lastLikeReaction.request.times[time_index].meeting_at,
                    'label': current_datetime_from_utc.toISOString().slice(0, current_datetime_from_utc.toISOString().lastIndexOf(":")).replaceAll('T', ' ')
                })
            }
        }
        return times
    }, [userHasLiked, lastLikeReaction?.request?.times])

    const onCloseMatchPopupFunction = useCallback(() => {
    }, [])
    const onConfirmMatchPopupFunction = useCallback(async (selectedTime) => {
        let request_times = []
        if (selectedTime) {
            request_times.push(selectedTime.value)
        }
        setIsShowMatchPopup(false)
        let response = await apiClient.post(`/feed/like/${userInfo.user_id}`, {
            'get_next_user': false,
            'skip': 0,
            'request_times': request_times
        })
        setUserHasLiked(false)
        tg.close()
    }, [userInfo, setUserHasLiked, tg])

    // --- Функция отвечающая за лайк пользователя ---

    const like = useCallback(async () => {
        if (!userHasLiked) {
            // TODO: отрытие предложения времени
        } else {
            if (currentMatchTimes.length !== 0) {
                setIsShowMatchPopup(true)
            } else {
                await onConfirmMatchPopupFunction('')
            }
        }
    }, [onConfirmMatchPopupFunction, currentMatchTimes, userHasLiked])

    // --- Функции для связи ---

    const callToPhone = useCallback(async () => {
        if (userInfo.phone_number) {
            if (tg.initDataUnsafe.hash) {
                tg.openLink(`${window.location.protocol + '//' + window.location.host}/redirect?redirect_url=tel:${userInfo.phone_number}`)
            } else {
                window.location.href = `tel:${userInfo.phone_number}`
            }
        }
    }, [userInfo, tg])

    const openTelegramUser = useCallback(() => {
        if (userInfo && userInfo.telegram_account && userInfo.telegram_account.username) {
            if (tg.initDataUnsafe.hash) {
                tg.openTelegramLink(`https://t.me/${userInfo.telegram_account.username}`)
            } else {
                window.location.href = `https://t.me/${userInfo.telegram_account.username}`
            }
        }
    }, [userInfo, tg])

    return (
        <FlowPages>
            {isShowBackButton && <Header isShowBackButton={true}/>}
            <div className="profile">
                <div className="general-info">
                    {isLoading ?
                        <div className="name"><Skeleton variant="text" width="60%"/></div>
                        :
                        <div
                            className="name">{userInfo.business_name}</div>
                    }
                    {isLoading ?
                        <div className="skills"><Skeleton variant="text" width="80%"/></div>
                        :
                        <div className="skills">
                            <div className="info">
                                {userInfo.business_type}
                            </div>
                        </div>
                    }
                </div>

                {(userIdOrMe === 'me') ? // TODO: Заменяем
                    <div className="edit-profile">
                        <ButtonOne onClick={() => navigate('/profile-edit')}>Редактировать анкету</ButtonOne>
                    </div>
                    :
                    null
                }


                {isLoading ?
                    <div className="profile-info">
                        <InfoBlock loading/>
                        <InfoBlock loading/>
                        <InfoBlock loading/>
                    </div>
                    :
                    <div className="profile-info">
                        <InfoBlock
                            key={0}
                            nameCategory={<>Средний чек</>}
                            text={[userInfo.average_payment + ' ₽']}
                        />
                        <InfoBlock
                            key={1}
                            nameCategory={<>INSTAGRAM</>}
                            list={[
                                `Подписчики: ${userInfo.subscribers_count} чел.`,
                                `${userInfo.instagram ? 'Имеется ссылка ✅' : 'Ссылку можно будет увидеть после метча ✅'}`
                            ]}
                        />
                        <InfoBlock
                            key={1211}
                            nameCategory={<>Скриншот профиля Instagram:</>}
                            text={[]}
                        />
                        <div className="avatar">
                            {isLoading ? <Skeleton variant="rectangular" width="100%" height="100%"/> :
                                userInfo.photos.length !== 0 && <img
                                    src={`${process.env.REACT_APP_BUSINESS_TINDER_API_URL}/static/${userInfo.photos[userInfo.photos.length - 1].image.file_name}`}
                                    alt=""/>}
                        </div>

                        {userIdOrMe === 'me' && <>
                            <InfoBlock
                                key={5}
                                nameCategory={'Данные для связи'}
                                text={[
                                    `Telegram: @${userInfo.telegram_account.username}`
                                ]}
                            />
                        </>}

                    </div>
                }

                {userHasLiked && !isLoading &&
                <UserLikedYou/>
                }

                {isLoading ?
                    null
                    :
                    <div className="sendMessage">
                        {userIdOrMe !== 'me' && userInfo.phone_number &&
                            <ButtonOne onClick={callToPhone}>
                                Позвонить
                            </ButtonOne>
                        }
                        {(userIdOrMe !== 'me' && userInfo.telegram_account && userInfo.telegram_account.username) &&
                        <ButtonTwo onClick={openTelegramUser}>
                            Написать
                        </ButtonTwo>
                        }
                        {userInfo.instagram &&
                            <ButtonTwo onClick={() => window.open(`https://www.instagram.com/${userInfo.instagram}`)}>
                                Перейти в инстаграм
                            </ButtonTwo>
                        }
                    </div>
                }
                {(
                    !isLoading && userIdOrMe !== 'me' &&
                    userHasLiked &&
                    !lastLikeReaction.request.is_accepted &&
                    !lastLikeReaction.request.is_rejected)
                &&
                    <div className="sendRequest">
                        <ButtonTwo onClick={like}>
                            Согласиться на встречу
                        </ButtonTwo>
                    </div>
                }
            </div>
            <PopUpDatePickerMatch
                isVisiblePopup={isShowMatchPopup}
                setIsVisiblePopup={setIsShowMatchPopup}
                onConfirmFunction={onConfirmMatchPopupFunction}
                matchTimes={currentMatchTimes}
            />
        </FlowPages>
    )

}

export default Profile;